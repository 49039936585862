@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "resources/assets/css/libs/font-awesome-4.7.0/scss/font-awesome";

body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

// Vue related
[v-cloak] {
  display: none;
}

.v-select .dropdown-toggle {
    height: 34px;
}

//! Vue related

.loader {
	display: none;
}

.fa-btn {
    margin-right: 6px;
}

img {
  margin: 20px 0;
	max-width: 100%;
	
	&.images-thumbnail {
    margin-top: 0px;
    margin-bottom: 0px;
	}
}

form .buttons {
	margin-top: 20px;

	.btn {
		margin-right: 20px;
	}
}

.display-inline,
form-control.display-inline {
  display: inline !important;
}

.full-width {
  width: 100% !important;
}

.margin-top-20 {
	margin-top: 20px !important;
}

.margin-top-10 {
	margin-top: 10px !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-top-0 {
  margin-top: 0px !important;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.padding-top-0 {
  padding-top: 0px !important;
}

.padding-left-0 {
  padding-left: 0px !important;
}

.padding-right-0 {
  padding-right: 0px !important;
}

.padding-left-10 {
  padding-left: 10px !important;
}

.padding-right-10 {
  padding-right: 10px !important;
}

.page-content {
	margin-bottom: 40px;
}

.border-top {
  border-top: 2px solid #666;
}

#accordion {
	margin: 20px 0;
}

#user-inner-layout {
	.navbar {
		margin-bottom: 0;
	}
}

#infscr-loading {
  margin: 0 auto;
  text-align: center;
}

tr.clickable-row {
  cursor: pointer;
}

a.send-status-message-button,
button.message-button {
  &:disabled {
    background-color: #666;
    border-color: #666;
  }
}

.user-page {
    font-family: Arial, 'Helvetica Neue', sans-serif;

    .row.content {
            padding: 10px;
    }

    a:not(.btn) {
            text-decoration: none;
            color: inherit !important;

            &.text-white {
              color: #fff !important;
            }
    }

/* Looks bad ? */
    #client-info {
      #client-number {
//        border: 2px solid #666;
//        border-radius: 5px;
//        margin-right: 30px;

        h3 {
          margin: 0;
          margin-left: 5px;
        }
      }

//      h5 {
//        margin-left: 5px;
//      }
//
    }

    #user-balance {
      color: #888;
    }

    i.fa-chevron-right {
      font-size: 20px;
    }

	input#total_fee {
		margin: 2.5em 0;
	}

	div.profile-actions {

        padding: 0 10px;

          .profile-action {
            font-size: 1.2em;

            img.profile-action-icon {
              display: inline-block;
              max-height: 40px;
              margin: 0;
              margin-right: 10px;
            }

            p {
              border-bottom: 2px #eee solid;
              padding-bottom: 0.6em;
            }

            &.first-action > p {
              border-top: 2px #eee solid;
              padding-top: .6em;
            }

            &:last-child > p {
              border-bottom: none;
              padding-bottom: 0;
              margin-bottom: 0px;
            }

            i.fa-chevron-right {
                margin-top: 0.2em;
            }

            &.single {

              p {
                border-bottom: none;
                padding-bottom: 15px;
              }
            }
          }
	}

    div#addresses-actions {
      padding: 0 10px;

      i.fa-chevron-right {
        margin-top: -0.4em;
      }

      ul#addresses-list {
        li {
          padding-left: 10px;
        }
      }
    }

    div#parcels-actions {
      padding: 0 10px 0.6em;

      .parcel {
        border-bottom: 2px #eee solid;
      }
    }

    div#user-page-footer {

      min-height: 300px;

      div#kartina-footer {

        form {
          button {
            margin-right: 30px;
          }

          a {
            color: #fff !important;
            font-size: 14px;
            text-decoration: none;
          }
        }
        
        a {
          font-size: 1.1em;
          color: #337ab7 !important;
          text-decoration: underline;
        }
      }

      div#balance-actions-footer {
        form {
          button {
            margin-right: 30px;
          }

          a {
            color: #fff !important;
            font-size: 14px;
            text-decoration: none;
          }
        }
      }
    }
}

.check-page {
  div.check-section {
    span.check-label {
      font-weight: bold;
    }
  }

  div.check-stamp {
    width:50%;
    margin: 0 auto;
    padding-top: 5px;
    border: 3px #8e90ce solid;
    border-radius: 10px;
    color: #8e90ce;

    p {
      text-align: center;
      &:first-child {
        margin-bottom: 20px;
      }
    }
  }

  div.check-footer {
    margin-top: 20px;

    a {
      cursor: pointer;
      color: #2a702c !important;
    }
  }
}

.grey-block {
	background: #ececec;
	min-height: 20px;

	h1,h2,h3,h4,h5,h6 {
		padding: 10px;
	}

  .subtitle {
    margin-top: -10px;
    padding-top: 0;
    font-weight: bold;
  }

  small {
    p {
      margin-bottom: 0;

      &:first-child {
        padding-top: 5px;
      }

      &:last-child {
        padding-bottom: 5px;
      }
    }
  }

  &.incoming-parcel-header {

    h5:first-child {
      padding-bottom: 0;
    }

    h5:nth-child(2) {
      margin-top: 0;
      padding-top: 0;
    }

    h5:nth-child(3) {
      margin-top: -20px;
    }
  }

  &.consolidation-header,
  &.outgoing-parcel-header {
		padding: 4px 10px;

		h4 {
			padding: 0;
		}

		p {
			margin-bottom: 0;
		}

		.btn {
			text-transform: uppercase;
		}
  }

  &.ip-heading {
  	h4 {
			margin-top: 0;
			margin-bottom: 0;
  	}

  	p {
  		margin-bottom: 0;
  	}

  	button {
  		padding: 2px 6px;
    	line-height: 1em;
    	margin: 4px auto;
  	}
  }
}

.parcel {
	
	font-weight: 800;

	p {
		margin: 0 0 4px;
	}

	span.light {
		color: #888;
		font-weight: 400;
	}

	hr {
		margin-top: 10px;
		margin-bottom: 5px;
	}
}

#transactions-page {
	.no-padding-left {
		padding-left: 0;
	}

	h4 {
		margin-top: 0;
		margin-bottom: 0;
	}
}

#addPartnerForm {

	a {
		text-decoration: none;
		cursor: pointer;

		&.removeStorageSelect {
			margin-left: 6px;
			width: 3%;
		}
	}

	.select2-container {
		margin: 6px 0;
		width: 96% !important;
	}
}

.item-page {

	p {
		margin: 0 0 4px;
	}

	.row.content {
		padding: 10px;
	}

	#itemFeatured {
		#imageFeatured {
			margin: 0;
			width: 100%;
		}
	}

	hr {
		margin-top: 10px;
		margin-bottom: 0;
	}

	h4 {
		margin-top: 0;
	}

	h5 {
		color: #888;
	}

}

.item-item {
	.thumbnail-container {
		padding-left: 20px;

		.thumbnail.content-photo {
			min-width: 84px;
		}
	}
}

#views-count-container {
	background: #F4F4F4;
        border-top: 1px solid #D8D8D8;
	min-height: 2em;
	line-height: 2em;
	color: #8B8B8B;
	display: flex;
	align-items: center;

	#views-count,
	#page-subscription {
		display: inline-block;
	}

	#views-count {
		line-height: 2.5em;
	}

	#page-subscription {

		p {
			display: inline-block;
			line-height: 1.2em;
			margin: 2px 0 0 0;
			font-size: .8em;
		}
	}
}

// HCat products

#hp-search {
  padding-right: 2%;
}

#hp-search-input {
  margin-left: 2%;
  margin-right: 2%;
  width: 95%;
}

.hproduct-content .hproduct-bottom {
  height: 30px;
  line-height: 40px;
}

.hproduct-content .hproduct-star {
  float: right;
  margin-right: 10px;
  color: #666;
  font-size: 30px;
  visibility: hidden;

  &:before {
   content: "\2606";
   position: absolute;
   visibility:visible;
  }

  &:checked:before {
   content: "\2605";
     position: absolute;
  }
}

.hproduct-content .hproduct-description {
  font-size: 0.9em;
}

.hproduct-content .hproduct-images {

  img {
    margin: 6px 0 !important;
  }

  div[class^="col-md-"], div[class^="col-xs-"] {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.incoming-parcels-batch-panel {
  margin-top: 30px !important;

  .select-block {
    margin-bottom: 30px !important;
  }

  .numbers-column {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .user-info-display {
    margin-top: 10px;
  }
}

.info-box {
  button {
    margin-left: 10px;
  }
}

// Consolidations
div#sender-buttons .list-button,
div#addressee-buttons .list-button {
	margin-bottom: 6px;
}

div.address {

	padding-top: 10px;

	ul.addresses-list {
		list-style-type: none;
		padding-left: 0;

		li.address-list-item {
			border-top: 1px solid #666;
			cursor: pointer;

			&:last-child {
				border-bottom: 1px solid #666;
			}

			p {
				padding-top: 5px;

				&:nth-child(2) {
					margin-top: -15px;
				}
			}
		}
	}

}

div.consolidation-edit {

	margin-top: 10px;

	.row {
		margin-top: 14px !important;
	}

	.table {
		margin-bottom: 0;
	}

	input[type="text"] {
		text-align: right;
	}

	span.parcel-title {
		font-weight: 800;
		color: #b9852a;
	}

	input.input-insurance_percent {
		display: inline-block;
		width: 80%;
		margin-right: 2px;
	}

	span.contents-price, span.contents-weight {
		font-size: 0.9em;
	}
}

// Admin user profile edit
div#user-user-edit-page {

	margin-bottom: 20px;

	div#addresses-list {

		.list .list-item {
			border-bottom: 1px solid #666;

			.address-line {
				margin-bottom: 10px;
			}

			.buttons {
				margin: 10px 0;
			}	
		}
	}

}

// Admin address edit
div.old-file-container {
	margin-bottom: 10px;

	button {
		margin-left: 10px;
	}
}

// User consolidations and outgoing parcels

#consolidations-wrapper > .consolidation-item,
#parcels-wrapper > .parcel-item {
	.address {
		font-size: 0.9em;
	}

	a > p {
		text-transform: uppercase;

		span {
			padding-bottom: 2px;
		}
	}

	span.delivery-type-line {
		font-size: 0.8em;
	}

	div.outgoing-parcel-header.border-top {
		border-top: 4px solid #337ab7;

		p.id {
			font-weight: bold;
			font-size: 1.2em;
			padding-bottom: 5px;
		}

		a.confirm-sending {
			margin-top: 10px;
			font-size: 0.8em;
		}
	}

	div.outgoing-parcel-body {
		margin: 0;
		padding: 0;

		table.services {
			margin: 0 -5px;
		}
	}
}

#consolidation-wrapper {
	#contacts-info {
		font-size: 0.8em;
	}
	
	#footer {
		padding-top: 30px;
		font-size: 0.8em;
		min-height: 200px;
	}
}

// Balance div on outgoing parcels user page
div.balance {
	margin: -30px 15px 0 10px;

	div#add-funds {
		margin-left: -10px;

		border-top: 1px solid #ececec;
	}
}

// Storage menu

div#storage-menu-container {

	hr {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	a.btn {
		min-width: 100%;
	}

	div#storage-menu-toggle {
		margin-bottom: 15px;

		a {
			text-decoration: none;
		}

	}

}

// Tables search
#table-search {

	label {
		display: block;
	}

	.form-group {
		margin-top: 10px;
		margin-right: 40px;
	}
}

table#contents-table {
	font-size: 0.85em;
	td {
		padding-top: 0px;

		img {
			margin-top: 2px;
		}
	}
}


@media (max-width: 767px) {
	#views-count-container {
		.container {
			width: 100%;
		}
	}
}

#comments {
	background: #ECECEC;
	font-family: Arial, 'Helvetica Neue', sans-serif;

	#comments-wrapper {
		#keyboard-margin {
			height: 250px;
		}
	}

	.comment-wrapper {
		margin-top: 10px;
	}

	.created_at {
		margin-top: 6px;

		.comment-time {
			background: #D5D5D5;
			margin-top: 2px;
			padding: 2px 8px;
			border-radius: 3px;
			color: #FBFBFB;
		}
	}

	.avatar {
		padding-right: 0px;
	}

	.user-name {

		h6 {
			display: inline-block !important;
			color: #AEAEAE;
			margin-bottom: 4px;
			padding-left: 0.4em;

			&.client-code {
				color: #808080;
			}
		}
	}

	.bubble 
	{
		position: relative;
		max-width: 100%;
		padding: 0.5em 1em;
		background: #FFFFFF;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;

		display: inline-block !important;

		&.with-image {
			padding: 2px;

			img {
				margin: 4px 0;
			}
		}

		.comment-image {
			position: relative;
			overflow: hidden;
			z-index: 2;
		}
	}

	.bubble:after 
	{
		content: '';
		position: absolute;
		border-style: solid;
		border-width: 10px 15px 10px 0;
		border-color: transparent #FFFFFF;
		display: block;
		width: 0;
		z-index: 0;
		margin-top: -10px;
		left: -8px;
		top: 16px;
	}


	#add-new-comment {
		border-top: 10px solid #3A393E;
		border-bottom: 10px solid #3A393E;
		background: #fff;
		position: relative;

		textarea {
			display: none;
		}

		input[name=image_upload] {
			display: none;
		}

		#editable-container {

			padding: 4px 15px;
			height: 96px;
			overflow-y: scroll;
			margin-right: -15px;

			#textarea {
				-moz-appearance: textfield-multiline;
				-webkit-appearance: textarea;
				border: none;
				margin-bottom: 0;
				font: medium -moz-fixed;
				font: -webkit-small-control;
				font-size: 14px;
				overflow: auto;
				resize: none;

				&:focus {
					outline: none;
				}

				&:empty:before {
					content: attr(placeholder);
					color: #bbb;
					display: block; /* For Firefox */
				}
			}

			#preview_images > .preview {
				position: relative;
				color: #fff; // trick to hide text
				max-width: 100px;

				img {
					border: 1px solid #D8D8D8;
					border-radius: 5px;
					padding: 3px;
					margin: 16px 0;
					max-width: 100px;
					height: auto;
				}

				button {
					border-radius: 50%;
					padding: 0px 4px;
					position: absolute;
					right: -5px;
					top: 5px;
					color: #777;
					border: 1px solid #aaa;
					background: rgba(255, 255, 255, 0.7);
				}
			}

			#added-image {
				display: inline-block;
				border: 1px solid #D8D8D8;
				border-radius: 5px;
				padding: 3px;
				margin: 16px 0;
				position: relative;

				img {
					margin: 0;
				}

				i {
					position: absolute;
					top: -12px;
					right: -12px;
					font-size: 16px;
					color: #777;
					border: 1px solid #aaa;
					border-radius: 100px;
					padding: 3px;
					background: rgba(255, 255, 255, 0.7);
				}
			}
		}

		#new-comment-actions {
			background: #F4F4F4;
			border-top: 1px solid #D8D8D8;
			padding: 4px 15px;
			overflow: hidden;

			img {
				margin: 0 8px 0 0;
				height: 30px;

				&.muted {
					opacity: 0.3;
				}
			}

			button[type=submit] {
				margin-left: 10px;
			}
		}
	}
}

.login-needed {
	width: 100%;
	display: none;

	&#upload-login-needed {
		position: absolute;
		bottom: 43px;
	}

	.btn {
		width: 100%;
		border-radius: 0;
		opacity: 0.8;
	}
}

#catalogue {
	margin-top: 10px;

	a {
		cursor: pointer;
		text-decoration: none;
		color: #bbb;
	}

	.panel {
		margin-bottom: 10px;

		.panel-body {
			padding: 4px 10px;
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+16,ffffff+16,f6f6f6+100 */
			background: #ffffff; /* Old browsers */
			background: -moz-linear-gradient(top,  #ffffff 16%, #ffffff 16%, #f6f6f6 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top,  #ffffff 16%,#ffffff 16%,#f6f6f6 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom,  #ffffff 16%,#ffffff 16%,#f6f6f6 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f6f6f6',GradientType=0 ); /* IE6-9 */

			span.category-name {
				color: #444;
				font-size: 16px;
				font-weight: 400;
			}

			p {
				margin-bottom: 0;
			}

			.next-page-icon {
				font-size: 24px;
				padding-top: 8px;
			}
		}
	}
}


#contents-table {
  hr {
    margin-top: 0;
    margin-bottom: 3px;
  }
}

// Incoming parcel content photos previews
.image-preview-item img {
		min-height: 120px;
		width: 100%;
}

.image-preview-item.new > .image-actions-container{
		margin-top: 40px;
}

.incoming-parcel-inline-detail {
	margin-right: 3em;
}

/**
* DataTables
*/
table.dataTable thead .sorting, 
table.dataTable thead .sorting_asc, 
table.dataTable thead .sorting_desc {
    background-image : none !important;
}

/**
* PDF
*/

body.pdf {

  img.barcode {
    margin: 0px;
  }

  span.storage-state {
    font-weight: bold;
    padding-left: 20px;
  }

  div#comment {
    margin-bottom: 20px;

    span#comment-text {
      font-weight: bold;
      font-style: italic;
      font-size: 1.2em;
    }
  }

  &.sticker {
    font-size: 40px;

    div.user-id {
      text-align: center;
    }
  }

  table {
    width: 100%;
  }

  .table-label {
    font-size: 18px;
  }
}



// Web users

.web-page {

  .main-logo-image img{
    width: 100%;
  }

  #web-menu-bar {
    background-color: #00C059;

    .menu-item {
      list-style: none;
      
      &:nth-child(2) {
        border-left: 1px solid #eee;
        border-right: 1px solid #eee;
      }

      &.active {
        a {
          color: #e6fb68;
        }
      }

      a {
        color: #fff;
        font-size: 28px;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
  }

  .container.web {
    border: none;

    .btn-success {
      background-color: #00C059;
      border-color: #00C059;
    }
  }

  .action {
    margin-top: 10px;

    .action-icon {
      font-size: 30px;
    }

    .action-description {
      font-size: 18px;
      font-weight: bold;
      line-height: 44px;
    }

    .separators>i.separator {
      display: block;
      margin-top: 8px;
    }

    &.no-margin-top {
      margin-top: 0px;
    }
  }


  .linethrough{
    position:relative;
    text-align: center
  }
  .linethrough:before {
    content: "";
    display: block;
    border-top: solid 2px #bebebe;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 50%;
    z-index: 0;
  }
  .linethrough span {
    background: #F5F5F5;
    padding: 0 10px;
    position: relative;
    z-index: 1;
  }

  .panel {
    border-radius: 1px;
    border: 2px solid #88ab8a;

    .panel-heading {
      border-bottom: 0;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .panel-body {
      background: #f5f5f5;


      &.bordered {
        border: 1px solid #eee !important;
        border-top: 3px solid #ff4400 !important;
        margin-top: 10px;
      }
    }
  }

  .form-web {
    margin-top: 40px;

    .form-group {
      margin-bottom: 30px;

      .input_with_icon { position: relative; }
      .input_with_icon input { text-indent: 30px;}
      .input_with_icon .fa {
        position: absolute;
        top: 6px;
        left: 10px;
        font-size: 20px;
      }

      .btn-secondary {
        background: #bbb;
        color: #f0f0f0;
      }
    }

    #bottom-links {
      float: right;

      a {
        color: #666;
      }
    }
  }

  .row.thumbnail-container {
    margin-left: 0;
  }

  .btn-submit {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: uppercase;

    &.btn-warning {
      background-color: #FF4400;
    }

    &.btn-success {
      background-color: #70B45F;
    }

    &.btn-next {
      width: 30% !important;
      margin: 10px;
    }
  }

  .iti-flag {background-image: url("/img/vendor/intlTelInput/flags.png") !important;}

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2 / 1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
    .iti-flag {background-image: url("/img/vendor/intlTelInput/flags@2x.png") !important;}
  }

  #verify-slider {
    width: 50%;
    text-align: center;
  }

  .dragdealer {
    position: absolute;
    left: 50%;
    top: 40%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    height: 40px;
    background: #e1e1e1;
    border-radius: 3px;
  }

  .dragdealer .handle {
    height: 40px;
    font-size: 16px;
    line-height: 40px;
    border-radius: 3px;
    width: 14%;
  }

  .dragdealer .disabled {
    background-color: #3C763D;
    color: #fff;
    cursor: default;
  }

  .dragdealer .slide-text {
    font-size: 1.3em;
    color: #888;
    padding-left: 10%;
    padding-top: 6px;
  }

  .panel-profile {
    border: 0 !important;
    box-shadow: none;

    .panel-body {
      background-color: #fff !important;
      border: 0;
      padding-top: 60px;
    }

    .panel-icon {
      font-size: 1.2em;
    }
  }

  #verification-info {
    margin-top: -10px;
    padding-bottom: 30px;
  }

  .icon-info {
    display: inline-block;
    font-size: 1.2em;
    padding-right: 10px;
    color: #14B4E6;
  }

  .nav-tabs.profile-tabs-outer {
    position: relative;

    li {
      width: 33%;

      i.circle-icon {
        display: inline-block;
        background-color: #888;
        color: #eee;
        margin: 6px;
        border-radius: 30px;
        height: 30px;
        width: 30px;
        font-size: 1.2em;
        text-align: center;
      }

      &.active {
        border-bottom: 3px solid #ff4400;

        i.circle-icon {
          background-color: #ff4400;
        }
      }

      a {
        font-size: 1.2em;
        font-weight: bold;
        border: 0;
        color: #888;

        &:hover {
          border: 0;
          cursor: default;
          background-color: inherit;
        }
      }
    }
  }
  

  .profile-tabs-inner li a[role=tab]{
    color: #888;
  }

  .profile-tabs-inner > li.active > a[role=tab]{
    color: #000;
  }

  .nav.nav-tabs.app-tabs {
    margin-bottom: 10px;

    font-weight: bold;
    font-size: 1.2em;
  }

  .tab-content {
    border: 1px solid #ddd;
    border-top: none;

    &.inner {
      border: none;
    }
  }


  ul.radio-with-images {
    padding: 0;
    list-style: none;
  }

  ul.radio-with-images img {
    height: 80px;
    margin: 0;
  }

}

.web-page-link-footer {
  margin-top: 40px;
  height: 60px;
  line-height: 60px;
  border-top: 1px solid #eee;

    .links-container {
      float:left;
      width: 100%;

      span.link-container {
        float: right;
        margin-left: 30px;

        a {
          color: #777;
        }
      }
    }

}


.web-page-footer {
  padding-top: 10px;
  min-height: 100px;
  line-height: 2em;
  color: #777;
  background-color: #f8f8f8;
}

// Profiles
.profile-tab {
  padding: 20px;
  background: #ddd;

  .profile-item {
    padding: 10px 0;
    border-bottom: 2px #999 solid;
  }

  .profile-field {
    margin-bottom: 10px;

    span {
      color: #888;
      font-size: 1.1em;
    }
  }
}

#change-phone-dialog {
  div#phone-number-info {
    padding-left: 20px;
  }

  div#verification-info p {
    padding-left: 10px;
  }

  div.intl-tel-input {
    width: 100%;
  }
}


/**
* Select2
 */

.select2-container {
  width: 100% !important;
}

.select2-selection {
  // to fit bootstrap3 style
  height: 34px !important;
}

@media (max-width: 1000px) {
  .web.tab-content {
    margin: 60px;
    margin-top: 20px;
  }

  .web-page {
    #web-menu-bar .menu-item a {
      font-size: 18px;
    }

    .action .action-description {
      line-height: inherit;
    }
  }
}

@media (max-width: 767px) {
  .web-page-footer {
    height: 40px;
    line-height: 40px;
    float: left;
    width: 100%;
  }
}

@media screen and (max-width: 616px) {
  .web-page-link-footer {
    .links-container {
      line-height: 2em;
    }
  }
}

/*
*	iOS styled switches
*/ 
.ios7-switch {
    display: inline-block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
	font-size: 1.5em;
	margin-bottom: 0;
	padding-left: .5em;
}

.ios7-switch input {
    opacity: 0;
    position: absolute;
	margin-top: 0;
}

.ios7-switch input + span {
    position: relative;
    display: inline-block;
    width: 1.65em;
    height: 1em;
    background: white;
    box-shadow: inset 0 0 0 0.0625em #e9e9e9;
    border-radius: 0.5em;
    // vertical-align: -0.15em;
    transition: all 0.40s cubic-bezier(.17,.67,.43,.98);
}

.ios7-switch:active input + span,
.ios7-switch input + span:active {
    box-shadow: inset 0 0 0 0.73em #e9e9e9;
}

.ios7-switch input + span:after {
    position: absolute;
    display: block;
    content: '';
    width: 0.875em;
    height: 0.875em;
    border-radius: 0.4375em;
    top: 0.0625em;
    left: 0.0625em;
    background: white;
    box-shadow: inset 0 0 0 0.03em rgba(0,0,0,0.1),
                0 0 0.05em rgba(0,0,0,0.05),
                0 0.1em 0.2em rgba(0,0,0,0.2);
    transition: all 0.25s ease-out;
}

.ios7-switch:active input + span:after,
.ios7-switch input + span:active:after {
    width: 1.15em;
}

.ios7-switch input:checked + span {
    box-shadow: inset 0 0 0 0.73em #4cd964;
}

.ios7-switch input:checked + span:after {
    left: 0.7125em;
}

.ios7-switch:active input:checked + span:after,
.ios7-switch input:checked + span:active:after {
    left: 0.4375em;
}

/* accessibility styles */
.ios7-switch input:focus + span:after {
    box-shadow: inset 0 0 0 0.03em rgba(0,0,0,0.15),
                0 0 0.05em rgba(0,0,0,0.08),
                0 0.1em 0.2em rgba(0,0,0,0.3);
    background: #fff;
}

.ios7-switch input:focus + span {
    box-shadow: inset 0 0 0 0.0625em #dadada;
}

.ios7-switch input:focus:checked + span {
    box-shadow: inset 0 0 0 0.73em #33be4b;
}

/* reset accessibility style on hover */
.ios7-switch:hover input:focus + span:after {
    box-shadow: inset 0 0 0 0.03em rgba(0,0,0,0.1),
                0 0 0.05em rgba(0,0,0,0.05),
                0 0.1em 0.2em rgba(0,0,0,0.2);
    background: #fff;
}

.ios7-switch:hover input:focus + span {
    box-shadow: inset 0 0 0 0.0625em #e9e9e9;
}

.ios7-switch:hover input:focus:checked + span {
    box-shadow: inset 0 0 0 0.73em #4cd964;
}
